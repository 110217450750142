@use '../../variables' as *

.List
    flex: 1
    max-width: 500px
    > .ListItem
        display: flex
        justify-content: space-between
        align-items: center
        height: 32px
        &:not(:last-child)
            border-bottom: 1px solid rgba(0,0,0,.1)
            
        > div
            display: flex
            align-items: center
            gap: 0.5rem