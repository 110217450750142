@use '../../variables' as *

.Simulador
    border-radius: 1rem
    padding: 1rem
    background-color: $color-white
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1)
    width: 500px
    display: flex
    flex-direction: column
    gap: 1rem
    &__Header
        display: flex
        align-items: flex-start
        justify-content: space-between
        .h5
            margin-bottom: 0
    &__Fields
        display: flex
        flex-direction: column
        gap: 0.65rem
        margin: 1rem 0
        &__Selector
            display: flex
            gap: 1rem
            align-items: center
            padding: 1.5rem 0 0 0
            justify-content: center
        &__Field
            font-size: 0.95rem
            display: flex
            justify-content: space-between
            align-items: center
            > .editable
                display: flex
                gap: 0.5rem
                align-items: center

.EditSaldo
    padding: 1rem 1rem 0rem 1rem
    min-width: 200px
    > .Opts
        display: flex
        align-items: center
        gap: 1rem
        margin-bottom: 1rem
    > .actions
        display: flex
        gap: 1rem