@use '../../variables.sass' as *
    
.SocialButton
    border: 0
    padding:0.5rem 1rem
    border-radius: 0.5rem
    font-family: Roboto
    font-weight: 500
    cursor: pointer
    color: $color-white
    user-select: none
    display: flex
    align-items: center
    white-space: nowrap
    border: 1px solid
    > svg
        margin-right: 1rem
    &.facebook
        background-color: #3B5998
        border-color: #a6b4d2
    &.google
        background-color: $color-background-gray
        color: $text-primary-color
        border-color: rgba(0,0,0, 0.1)
    &.fieldview
        background-color: $text-primary-color
        border-color: $color-black-opacity
    &.small
        font-size: .825rem
        padding: 0.25rem .5rem
    &.full
        width: 100%
    > .labelgroup
        display: flex
        flex-direction: column
        align-items: flex-start
    &:disabled
        color: $text-primary-color
        background-color: $color-light-black-opacity
        pointer-events: none
        opacity: 0.38
        cursor: not-allowed
        box-shadow: none
    &.alternative
        height: 48px
        justify-content: center
        > .labelgroup
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center
            font-size: 16px
            gap: 0.25rem
            > .label
                margin-top: 1px
                font-size: 16px
            > .media