@use '../../variables' as *

.Details
    width: 100%
    height: 100%
    > .content
        padding: 2rem
        margin: auto
        width: 100%
        max-width: 62.5rem
    &__Info
        display: flex
        justify-content: space-between
        margin-bottom: 2rem
        &__Group
            display: flex
            align-items: center
            gap: 1rem
        &__Image
            width: 3rem
            height: 3rem
            border-radius: 2rem
            overflow: hidden
            > img
                width: 100%
                height: 100%
        &__Name
            width: 200px
            > .caption
                color: $text-secondary-color
                margin-bottom: 0.5rem
    &__Field
        margin-right: 1rem
        > .caption
            color: $text-secondary-color
            margin-bottom: 0.5rem
    &__Stats
        display: flex
        gap: 2rem
        .boxed
            border-radius: 0.25rem
            border: 1px solid $color-light-black-opacity
            padding: 1rem
            > .overline
                margin-bottom: 1.5rem
        &__Left
            display: flex
            flex-direction: column
            gap: 2rem
            width: 100%
        &__Right
            width: 100%
        &__User
            display: flex
            align-items: center
            font-style: italic
            color: $text-secondary-color
            > .Icon
                margin-right: 1rem