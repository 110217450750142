@use '../../variables.sass' as *
    
.DropdownItem
    display: flex
    align-items: center
    padding: 0.65rem 1rem
    cursor: pointer
    color: $text-primary-color
    user-select: none
    margin: 4px
    > .Icon
        margin-right: 0.5rem
    &:hover
        background-color: rgba(0,0,0,.03)
    &.selected
        color: $text-primary-color
        background-color: $color-background-gray
        margin: 4px
        border-radius: 4px