@use '../../variables.sass' as *
    
.StatusTag
    padding: 0.125rem 0.35rem
    border-radius: 0.25rem
    user-select: none
    &.neutral-low
        box-shadow: inset 0 0 0 1px $accent-color 
        color: $accent-color 
    &.neutral
        background-color: $accent-color
        color: $color-white
    &.success-low
        box-shadow: inset 0 0 0 1px $primary-color
        color: $primary-color 
    &.success
        background-color: $primary-color
        color: $color-white
    &.error-low
        box-shadow: inset 0 0 0 1px $color-status-error
        color: $color-status-error 
    &.error
        background-color: $color-status-error
        color: $color-white
    &.disabled-low
        box-shadow: inset 0 0 0 1px $color-text-label
        color: $color-text-label 
    &.disabled
        background-color: $color-text-label
        color: $color-white