@use '../../variables' as *

.DataInput
    > label
        text-transform: uppercase
        font-size: 10px
        font-weight: bold
    > input
        width: 80px
        padding: 0.25rem
        border-radius: 0
        border: 0
        // border-top: 1px solid $color-black-opacity
        border-left: 1px solid $color-black-opacity
        &:last-child
            border-bottom: 1px solid $color-black-opacity
            