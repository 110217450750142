@use '../../variables' as *

.Home
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center

.Logo
    width: 100px

.LabItemsList
    margin: 3rem auto 0 auto
    width: 100%
    max-width: 940px
    display: flex
    flex-direction: column
    gap: 1rem
    flex-wrap: wrap

.LabItem
    box-shadow: 2px 2px 10px rgba(0,0,0, 0.1)
    border-radius: 0.5rem
    width: 100%
    overflow: hidden
    &__Image
        background-color: $color-light-black-opacity
        padding-bottom: 50%
        position: relative
        overflow: hidden
        border-bottom: 1px solid $color-light-black-opacity
        > img 
            opacity: 0.8
            position: absolute
    &__Line
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 1rem
        padding: 1rem
        > .description
            height: 38px
        &__Actions
            width: 100%
            display: flex
            align-items: center
            justify-content: space-between