@use '../../variables.sass' as *
    
.Tabs
    display: flex
    user-select: none
    > .Tab
        color: $color-text-label
        padding: 0.75rem 1.5rem
        cursor: pointer
        font-size: 14px 
        text-transform: uppercase
        font-weight: 500
        position: relative
        &.active
            color: $primary-color
            border-bottom: 2px solid $primary-color
        > .Counter
            position: absolute
            top: 0.2rem
            right: -0.2rem
    &.full
        width: 100%
        > .Tab
            width: 100%
            text-align: center
    &.no-case
        > .Tab
            text-transform: none
    &.floater
        >.Tab
            color: $color-text-label
            padding: 0.5rem 0.75rem
            border-radius: 0.25rem
            cursor: pointer
            font-size: 14px 
            text-transform: uppercase
            font-weight: 500
            position: relative
            &.active
                color: $color-white
                background: $primary-color
                border-bottom: 0
    &.floater2
        gap: 1rem
        >.Tab
            color: $text-primary-color
            padding: 0.25rem 0.5rem
            border-radius: 0.25rem
            cursor: pointer
            font-size: 1rem
            text-transform: capitalize
            font-weight: 500
            position: relative
            &.active
                color: $color-white
                background: $primary-color
                border-bottom: 0
                font-weight: bold
    &.floater2-vertical
        flex-direction: column
        align-items: flex-start
        gap: 1rem
        >.Tab
            color: $text-primary-color
            padding: 0.25rem 0.5rem
            border-radius: 0.25rem
            cursor: pointer
            font-size: 1rem
            text-transform: capitalize
            font-weight: 500
            position: relative
            &.active
                color: $color-white
                background: $primary-color
                border-bottom: 0
                font-weight: bold
    &.paper
        >.Tab
            color: $color-text-label
            padding: 0.75rem 1rem
            border-radius: 0.5rem 0.5rem 0 0
            cursor: pointer
            font-size: 1rem
            text-transform: capitalize
            font-weight: 500
            position: relative
            &.active
                color: $primary-color
                background: $color-white
                border: 1px solid $color-light-black-opacity
                border-bottom: 0
                box-shadow: 0 -6px 8px rgba(0,0,0,0.1)
