@use '../../../../variables' as *

.NFeSectionGroup
    background-color: $color-white
    border-radius: .75rem
    max-width: 1024px
    margin: 0 auto 2rem
    width: 100%
    height: fit-content
    position: relative
    overflow: hidden
    padding: 2rem 0rem 0rem 2rem 
    display: flex
    gap: 1rem
    flex-direction: column
    &::after
        content: ''
        width: 10px
        height: 100%
        background-color: #D9D9D9
        position: absolute
        top: 0
        left: 0
    > .NFeTitle
        margin-left: 1rem
    > .over
        position: absolute
        top: 0
        right: 0
        text-transform: uppercase
        font-size: 0.65rem
        color: $text-secondary-color
        display: flex
        align-items: center
        gap: .5rem
        padding: 1rem
        cursor: pointer
    &.valid
        &::after
            background-color: $primary-color
    > .NFeSection
        border-radius: 0.75rem 0 0 0.75rem
        box-shadow: 0 0 0 6px $color-background-gray
        &::after
            display: none

.NFeSection
    background-color: $color-white
    border-radius: 0.75rem
    max-width: 1024px
    margin: 0 auto 2rem
    width: 100%
    height: fit-content
    position: relative
    overflow: hidden
    padding: 2rem 3rem 
    display: flex
    gap: 1rem
    flex-direction: column
    &::after
        content: ''
        width: 10px
        height: 100%
        background-color: #D9D9D9
        position: absolute
        top: 0
        left: 0
    > .over
        position: absolute
        top: 0
        right: 0
        text-transform: uppercase
        font-size: 0.65rem
        color: $text-secondary-color
        display: flex
        align-items: center
        gap: .5rem
        padding: 1rem
        cursor: pointer
    &.valid
        &::after
            background-color: $primary-color
    > .Button
        margin: 0 auto

.NFeTitle
    display: flex
    gap: .75rem
    align-items: center
    margin-bottom: 1rem
    > .Icon
        color: $text-secondary-color