@use '../../variables' as *

.NFeUpsellCrop
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    padding: 2rem
    > .Button
        position: absolute
        top: 1rem
        right: 1.5rem
    > img
        position: absolute
        left: 80%
        top: 10%
        width: 100%
        transform: translate(-50%, -30%)
        pointer-events: none
    > .ActionBar
        padding: 2rem
        position: absolute
        bottom: 0
        left: 0