@use '../../../../variables.sass' as *
    
.ConciliationHeader
    display: flex
    align-items: center
    justify-content: space-evenly
    position: sticky
    top: 0
    background: $color-white
    z-index: 1
    &__Transfer
        display: flex
        flex: 8
        width: 100%
        min-width: 0
        justify-content: space-between
        align-items: center
        padding: 0.5rem 1rem
        > div
            // flex: 1
            width: calc(50% - 2rem)
        > .Icon
            margin: 0 1rem
            color: $primary-color
        > .placeholder
            max-width: 24px
            width: 24px
            height: 24px
            margin: 0 1rem
    > .date
        width: 90px
        padding: 0.5rem 1rem
    > .category
        flex: 1
        min-width: 1rem
        display: flex
        align-items: center
        > .Tooltip
            margin-right: 0.5rem
    > .installment
        width: 50px
        text-align: center
        padding: 0.5rem 1rem
    > .value
        width: 150px
        text-align: right
        padding: 0.5rem 1rem
    > .placeholder
        width: 24px
        height: 24px
        &:last-child
            padding: 0.5rem 1rem
    > .Divider
        position: absolute
        bottom: 0
        left: 0
        width: calc(100% - 3rem)
