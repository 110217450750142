@use '../../../../variables.sass' as *
    
.ConciliationRow
    display: flex
    align-items: center
    justify-content: space-evenly
    position: relative
    border-radius: 0 2rem 2rem 0
    user-select: none
    cursor: pointer
    > .interactable
        position: absolute
        top: 0
        left: 0
        width: calc(100% - 3rem)
        height: 100%
        background-color: rgba(0,0,0, .0)
        pointer-events: none
        z-index: -1
    &__Transfer
        display: flex
        flex: 8
        width: 100%
        min-width: 0
        justify-content: space-between
        align-items: center
        padding: 0.5rem 1rem
        > div
            // flex: 1
            width: calc(50% - 2rem)
        > .Icon
            margin: 0 1rem
            color: $primary-color
    > .date
        width: 90px
        min-width: 90px
        padding: 0.5rem 1rem
    > .category
        flex: 1
        min-width: 1rem
        display: flex
        align-items: center
        > .Tooltip
            margin-right: 0.5rem
    > .installment
        width: 50px
        min-width: 50px
        text-align: center
        padding: 0.5rem 1rem
    > .value
        width: 150px
        text-align: right
        padding: 0.5rem 1rem
        color: $primary-color
    > .interaction
        opacity: 0
        padding: 0.5rem 1rem
    > .Divider
        position: absolute
        top: 0
        left: 0
        width: calc(100% - 3rem)
    > .Button
        z-index: 1
    &.negative
        > .value
            color: $color-status-error
        .ConciliationRow
            &__Transfer
                > .Icon
                    color: $color-status-error
    &:hover
        > .interactable
            background-color: rgba(0,0,0, .03)
        > .interaction
            opacity: 1
            pointer-events: all
    &.selected
        > *
            opacity: 0.5
            filter: grayscale(1)
        > .Divider
            opacity: 1
    &.cart
        > *
            opacity: 1
            filter: grayscale(0)
    &.linked
        > .interaction
            opacity: 1
        > *
            opacity: 1
            filter: grayscale(0)
        > .interactable
            // background-color: $color-brand-ultra-light
            background-color: $color-light-black-opacity
    &.matched
        > .interactable
            background-color: $color-brand-ultra-light