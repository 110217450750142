@use '../../variables' as *

.Indicadores
    width: 100%
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    &__Menu
        display: flex
        flex-direction: column
        height: 100%
        &__Top
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0.25rem 1rem
            // background-color: $color-light-black-opacity
            font-weight: bold
            height: 40px
        &__Bottom
    &__Content
        margin: 0 auto
        // max-width: 1024px
        display: flex
        flex-direction: column
        height: 100%
        
        &__Top
            height: 45px
            min-height: 46px
            display: flex
            align-items: center
            justify-content: space-between
            padding: 0 1rem
        &__Graphs
            padding: 3rem
            display: flex
            flex-direction: column
            gap: 2rem
            padding-bottom: 350px
            height: calc(100% - 6rem)
    
    .DataEditor
        width: calc(100% - 2rem)
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.1) ,2px 2px 24px rgba(0,0,0,.1)
        background: $color-white
        border-radius: 1rem
        position: fixed
        bottom: 1rem
        left: 1rem
        transform: translateY(110%)
        max-height: 300px
        display: flex
        flex-direction: column
        &__Header
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0.5rem 1rem
        &__Content
            padding: 1rem 1rem
            display: flex
            &__EditGroup
                > .label
                    height: 28px
                    text-transform: uppercase
                    font-size: 10px
                    font-weight: bold
                    display: flex
                    align-items: center
                    padding: 0 0.5rem
                    border-bottom: 1px solid $color-black-opacity
                    border-left: 1px solid $color-black-opacity
            &__EditLabels
                > .label
                    height: 23px
                    padding-right: 2px
                    font-size: 13px
                    font-weight: bold
                    border-bottom: 1px solid $color-black-opacity
                    display: flex
                    align-items: center
                    > .Button
                        margin-right: 0.25rem
                        height: 20px
                    > input
                        padding: 0.125rem
                        font-size: 13px
                        font-family: 'Roboto'
                        width: 100px
                        border: 0
                        &::placeholder
                            font-style: italic

                > .empty
                    width: 126px
                    height: 28px
                    padding: 0
                    border-bottom: 1px solid $color-black-opacity
        &.editing
            transform: translateY(0%)

.KPIAction
    user-select: none
    position: relative
    cursor: pointer
    &__Content
        display: flex
        align-items: flex-start
        padding: 0.75rem 1rem 0.75rem 0.5rem
        > .Icon
            margin-top: 1px
            min-width: 1rem
    .DropZone
        &__Up
            cursor: pointer
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 50%
            min-height: 0
            &.over
                &::after
                    pointer-events: none
                    z-index: 1
                    content: ''
                    position: absolute
                    top: -2px
                    left: 0
                    width: 100%
                    height: 4px
                    background-color:  $accent-color
        &__Down
            cursor: pointer
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            height: 50%
            min-height: 0
            &.over
                &::after
                    pointer-events: none
                    z-index: 1
                    content: ''
                    position: absolute
                    bottom: -2px
                    left: 0
                    width: 100%
                    height: 4px
                    background-color:  $accent-color
    &:hover
        background-color: $color-background-gray
    &.dragged
        cursor: grab
        pointer-events: none
        opacity: 0.1    
        .DropZone
            pointer-events: none
    &.hidden
        opacity: 0.2

.IndGraph
    border-radius: 0.5rem
    box-shadow: 0px -16px 20px rgba(0,0,0, 0.05)
    &__Wrapper
        width: 100%
        background-color: $color-background-gray
        padding-bottom: 1px
    &__Config
        width: 100%
        display: flex
        &__Menu
            border-right: 1px solid $color-light-black-opacity
            > div
                padding: 0.5rem 1rem
            > .active
                background-color: $color-light-black-opacity
        &__Content
            flex: 1
    &__Content
        padding: 2rem
        > .overline
            padding-bottom: 1rem
        &__Columns
            display: flex
            gap: 2rem
        &__Column
            flex: 1
        &__Data
            display: flex
    &__Header
        padding: 0.4rem 3.5rem 1rem 1rem
        display: flex
        justify-content: space-between
        align-items: center
        &.h4
            padding-top: 1rem
            padding-bottom: 0
        > .caption
            display: flex
            gap: 0.25rem

.RadioFamily
    display: flex
    gap: 1.5rem
    padding-top: 1.25rem
    position: relative
    padding-bottom: 1rem
    > label
        position: absolute
        top: 0
        left: 0
        font-size: 0.75rem
        color: $text-secondary-color