@use '../../variables.sass' as *
    
.Loader
    width: 1.5rem
    height: 1.5rem
    animation: rotate .5s infinite linear
    color: $color-brand-dark
    > circle
        stroke: currentColor
        stroke-width: 2
        stroke-dashoffset: 0
        stroke-dasharray: 64
        stroke-linecap: round
        animation: stroke 2s infinite linear
    @keyframes stroke
        0% 
            stroke-dashoffset: 0
        100% 
            stroke-dashoffset: 128
    @keyframes rotate
        0% 
            transform: rotate(0deg)
        100% 
            transform: rotate(360deg)