@use '../../variables' as *

.KRView
    position: relative
    padding: 0.5rem 0.75rem
    border-radius: 0.25rem
    font-size: 0.825rem
    color: $color-brand-dark
    background-color: $color-brand-ultra-light
    display: flex
    gap: 0.5rem
    align-items: center
    margin-bottom: 1rem
    > .Icon
        color: $color-brand-dark
        min-width: 1rem
    > .HoverCard
        width: calc(100% - 1rem)
        opacity: 0
        pointer-events: none
        padding: 0.5rem 0.75rem
        color: $color-brand-dark
        background-color: $color-brand-ultra-light
        position: absolute
        top: 0
        left: 0.5rem
        transform: translateY(-90%)
        display: flex
        gap: 0.5rem
        align-items: center
        border-bottom: 1px solid rgba($color-white, 1)
        border-radius: 0.25rem 0.25rem 0 0
        > .Icon
            color: $color-brand-dark
            min-width: 1rem
    > .LinkCard
        width: calc(100% - 1rem)
        opacity: 0
        pointer-events: none
        padding: 0.75rem
        color: $color-brand-dark
        background-color: $color-brand-ultra-light
        position: absolute
        bottom: 0
        left: 0.5rem
        transform: translateY(90%)
        display: flex
        gap: 0.5rem
        align-items: center
        border-top: 1px solid rgba($color-brand-dark, 0.2)
        border-radius: 0 0 0.25rem 0.25rem
        > a
            text-decoration: none
            background-color: rgba($color-brand-dark, 0.75)
            padding: 0.25rem 0.5rem
            border-radius: 1rem
            text-transform: uppercase
            font-size: 0.625rem
            font-weight: bold
            color: $color-brand-ultra-light
    &:hover
        box-shadow: 2px 2px 10px rgba(0,0,0, 0.1)
        > .HoverCard
            transform: translateY(-100%)
            pointer-events: all
            opacity: 1
        > .LinkCard
            transform: translateY(100%)
            pointer-events: all
            opacity: 1