@use '../../variables.sass' as *
    
.AegroMenu
    height: 60px
    width: 100%
    background-color: $primary-color
    display: flex
    justify-content: space-between
    align-items: center
    gap: 1rem
    padding: 0 1rem
    > .AegroLogo
        height: 1.5rem
        width: 1.5rem
    > .Button
        margin-left: 1rem
        .Icon
            color: $color-white
    > .spacer
        width: 100%
        flex: 1
    &__Tabs
        align-self: flex-end
        display: flex
        height: 45px
        user-select: none
        gap: 1rem
        > .Tab
            position: relative
            display: flex
            justify-content: center
            align-items: center
            padding: 0.5rem 1rem
            border-radius: 0.5rem 0.5rem 0 0 
            gap: 0.5rem
            font-weight: bold
            color: $color-white
            > .Action
                border: 2px solid $color-light-black-opacity
                border-radius: 4px
                cursor: pointer
                > .Icon
                    color: $color-white
                &:hover
                    background-color: $color-white
                    > .Icon
                        color: $primary-color
            > .round
                position: absolute
                width: 0.5rem
                height: 0.5rem
                bottom: 0
                left: -0.5rem
                overflow: hidden
                &::after
                    content: ''
                    background-color: $primary-color
                    width: 1rem
                    height: 1rem
                    border-radius: 50%
                    position: absolute
                    top: -0.5rem
                    left: -0.5rem
                &.right
                    left: auto
                    right: -0.5rem
                    &::after
                        left: auto
                        right: -0.5rem
            &:hover                
                background-color: rgba($color-light-black-opacity, 0.1)
                > .round
                    background-color: rgba($color-light-black-opacity, 0.1)
            &.active
                background-color: $color-white
                color: $primary-color                        
                > .Action
                    border: 2px solid $color-light-black-opacity
                    > .Icon
                        color: $primary-color
                    &:hover
                        background-color: $primary-color
                        > .Icon
                            color: $color-white
                > .round
                    background-color: $color-white
                    &::after
                        background-color: $primary-color
    &__Tools
        display: flex
        align-items: center
        gap: 1rem
        > .Button
            .Icon
                color: $color-white
        > .Profile
            background-color: $color-white
            display: flex
            justify-content: center
            align-items: center
            width: 2rem
            height: 2rem
            border-radius: 50%
            color: $color-light-black-opacity

.AegroMenu__OverContent
    box-shadow: 2px 2px 20px rgba(0,0,0, .15)
    background-color: $color-white
    padding: 1.5rem
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    gap: 1rem
    width: 350px
    &__Title
        display: flex
        gap: 1rem
        > .h5
            font-weight: 500
    &__Search
        background-color: $color-background-gray
        padding: 0.75rem
        border-radius: 0.5rem
        position: relative
        > input
            border-radius: inherit
            outline-color: $color-gray-opacity
            background-color: transparent
            border: 0
            position: absolute
            width: 100%
            height: 100%
            top: 0
            left: 0
            font-family: Roboto
            font-size: 1rem
            padding-left: 2.75rem
    &__List
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 1rem
        .Item
            width: 100%
            &__Title
                font-size: 1.25rem
                font-weight: 500
                color: $text-primary-color
            &__Subtitle
                color: $text-secondary-color