@use '../../variables' as *

.StatementView
    width: 100%
    position: relative
.StatementMessage
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
.Statement
    width: 100%
    position: relative
    > .content
        padding: 1rem 2rem
        > .Divider
            margin-bottom: 2rem
    &__Item
        display: flex
        margin-bottom: 2rem
        &__Date
            font-weight: 500
            text-transform: capitalize
            padding: 0 2rem 0 0
            white-space: nowrap
            width: 100px
            min-width: 100px
            max-width: 100px
            &__Floater
                position: sticky
                top: 90px
                > .weekday
                    font-size: 1rem
                    color: $text-secondary-color
                > .day
                    letter-spacing: -.2rem
                    font-size: 4.5rem
                    line-height: 4rem
                > .month
                    font-size: 1.2rem
                > .year
                    font-size: 1rem
        &__Lines
            flex: 1
            &__Line
                display: flex
                margin-bottom: 2rem
                &__OFX
                    min-width: 300px
                    max-width: 300px
                &__Status
                    width: 78px
                    height: fit-content
                    display: flex
                    align-items: center
                    > .dashedLine
                        height: 1px
                        flex: 1
                        border-bottom: 1px dashed $color-light-black-opacity
                        &.hide
                            opacity: 0
                    > .Status
                        width: 2rem
                        height: 2rem
                        border-radius: 1rem
                        display: flex
                        justify-content: center
                        align-items: center
                        > .ButtonDropdown
                            > .Tooltip
                                > .Button
                                    > .Icon
                                        color: $color-white
                        &.reconciled
                            background: $accent-color
                        &.ignored
                            background: $text-secondary-color
                &__BILLS
                    border: 1px solid $color-light-black-opacity
                    border-radius: 0.5rem
                    display: flex
                    flex-direction: column
                    height: fit-content
                    flex: 1