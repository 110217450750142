@use '../../variables.sass' as *
    
.Dropdown
    position: relative
    > div
        cursor: pointer
        > .InputText
            pointer-events: none
        > .Button
            right: 0
            top: calc(50% - 1.25rem)
            position: absolute
    &.outline
        > div
            > .Button
                right: 0.5rem


.fix
    max-height: inherit
    overflow: auto !important
    position: relative

.DropdownCard
    display: flex
    flex-direction: column

.above
    .DropdownCard
        flex-direction: column-reverse

.DropdownSearchBar
    display: flex
    align-items: center
    gap: 0.5rem
    padding: 0.25rem
    position: relative
    > input
        padding: 0.5rem 0.5rem 0.5rem 2rem
        border: 0
        border-radius: 0.25rem
        background-color: $color-background-gray
        width: 100%
        outline: 0
    > .Icon 
        position: absolute
        top: 0.75rem
        left: 0.75rem
    // position: fixed
    // background-color: $color-white
    // border-bottom: 1px solid $color-light-black-opacity

.DropdownList
    // padding-top: 2rem