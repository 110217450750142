@use '../../variables.sass' as *
    
.App
    width: 100%
    height: 100%
    position: relative
    display: block    
    overflow: hidden
    &__Content
        width: 100%
        height: 100%
        z-index: 0
        position: relative
    &__Overlay
        z-index: 1
    &__Tooltip
        position: absolute
        z-index: 2
        top: 0
        left: 0
        background: $color-gray-opacity
        color: $color-white
        font-size: 0.925rem
        font-weight: 500
        padding: 0.25rem 0.5rem
        border-radius: 0.25rem
        user-select: none
        pointer-events: none
        opacity: 0
        transition-property: opacity
        max-width: 250px
        width: max-content
        text-align: center
        &.visible
            opacity: 1
        &.top-left
            transform: scale(.7) translate(0, 1rem)
            &.visible
                transform: scale(1) translate(0, 1rem)
        &.top-middle
            transform: scale(.7) translate(-50%, 1rem)
            &.visible
                transform: scale(1) translate(-50%, 1rem)
        &.top-right
            transform: scale(.7) translate(-100%, 1rem)
            &.visible
                transform: scale(1) translate(-100%, 1rem)
        &.middle-left
            transform: scale(.7) translate(0, calc(-100% - 1rem))
            &.visible
                transform: scale(1) translate(0, calc(-100% - 1rem))
        &.middle-middle
            transform: scale(.7) translate(-50%, calc(-100% - 1rem))
            &.visible
                transform: scale(1) translate(-50%, calc(-100% - 1rem))
        &.middle-right
            transform: scale(.7) translate(-100%, calc(-100% - 1rem))
            &.visible
                transform: scale(1) translate(-100%, calc(-100% - 1rem))
        &.bottom-left
            transform: scale(.7) translate(0, calc(-100% - 1rem))
            &.visible
                transform: scale(1) translate(0, calc(-100% - 1rem))
        &.bottom-middle
            transform: scale(.7) translate(-50%, calc(-100% - 1rem))
            &.visible
                transform: scale(1) translate(-50%, calc(-100% - 1rem))
        &.bottom-right
            transform: scale(.7) translate(-100%, calc(-100% - 1rem))
            &.visible
                transform: scale(1) translate(-100%, calc(-100% - 1rem))


.Card
  background-color: $color-white
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1)
  border-radius: 0.25rem
  overflow: hidden