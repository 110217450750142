@use '../../variables.sass' as *
    
.Divider
    width: 100%
    height: 1px
    min-height: 1px
    background-color: $color-light-black-opacity
    &.dashed
        background-color: transparent
        border-bottom: 1px dashed $color-light-black-opacity
    &.vertical
        width: 1px
        min-width: 1px
        min-height: 100%
        height: 100%
        &.dashed
            background-color: transparent
            border-left: 1px dashed $color-light-black-opacity