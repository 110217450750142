@use '../../variables.sass' as *
    
.InputText
    display: flex
    flex-direction: column
    height: 4rem
    position: relative
    > label
        position: absolute
        color: $text-secondary-color
        top: 1rem
        pointer-events: none
        font-size: 16px
    > input
        outline: 0
        margin-top: 1rem
        background: transparent
        border: 0
        border-bottom: 1px solid $color-light-black-opacity
        padding: 0.25rem 0
        color: $text-primary-color
        transition-property: border-color
        &:focus             
            border-bottom: 2px solid $primary-color
            ~ label
                font-size: 12px
                top: 0
                color: $primary-color
        &:not(:placeholder-shown)
            ~ label
                font-size: 12px
                top: 0
        &:read-only
            color: $color-black-opacity
            ~ label
                color: $color-black-opacity
        &:disabled
            color: $text-primary-color
            ~ label
                color: $text-secondary-color
    > .description
        margin-top: 0.15rem
        font-size: 0.75rem
        color: $color-gray-opacity
    &.currency
        > input
            text-align: right
    &.outline
        > label
            background-color: $color-white
            padding: 0 0.25rem
            top: 1.5rem
            left: 0.75rem
        > input
            margin: 0
            border-radius: 0.5rem
            padding: 1.15rem 0.75rem 0.5rem 0.75rem
            border: 2px solid $color-light-black-opacity
            &:focus             
                border: 2px solid  $primary-color
                ~ label
                    font-size: 12px
                    top: -.4rem
                    color: $primary-color
            &:not(:placeholder-shown)
                ~ label
                    font-size: 12px
                    top: -.4rem