@use '../../variables.sass' as *
    
.CodeInput
    padding: 2rem 1rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    // width: fit-content
    gap: 3rem
    > form
        display: flex
        gap: 0.75rem
        > input
            text-align: center
            width: 30px
            height: 3rem
            font-size: 24px
            font-weight: 400
            border: 0
            box-shadow: 0 0 0 2px $color-light-black-opacity
            border-radius: 0.5rem
            outline: 0
            &:focus             
                box-shadow: 0 0 0 2px $color-black-opacity,0 0 12px rgba(0,0,0, .4)
            &:not(:placeholder-shown)
                box-shadow: 0 0 0 2px $color-black-opacity
    >.message
        width: 250px
        text-align: center
        height: 50px
    &.error
        >.message
            color: $color-status-error
        > form
            > input
                color: $color-status-error
                box-shadow: 0 0 0 2px $color-status-error
                &:focus             
                    box-shadow: 0 0 0 2px $color-status-error,0 0 12px rgba($color-status-error, .4)
                &:not(:placeholder-shown)
                    box-shadow: 0 0 0 2px $color-status-error