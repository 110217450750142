@use '../../../../variables.sass' as *
    
.ReconciledRow
    padding: 0.5rem 1rem
    display: flex
    align-items: center
    justify-content: space-evenly
    gap: 1rem
    position: relative
    user-select: none
    &__Transfer
        display: flex
        flex: 1
        justify-content: space-between
        align-items: center
        pointer-events: none
        > div
            flex: 1
        > .Icon
            margin: 0 1rem
            color: $primary-color
    > .installment
        width: 50px
        text-align: center
        pointer-events: none
    > .value
        width: 150px
        text-align: right
        color: $primary-color
        pointer-events: none
    > .Button
        z-index: 1
    &.negative
        > .value
            color: $color-status-error
        .ReconciledRow
            &__Transfer
                > .Icon
                    color: $color-status-error
    &.selected
        > *
            opacity: 0.5
            filter: grayscale(1)
    &.cart
        > *
            opacity: 1
            filter: grayscale(0)
    &.linked
        > *
            filter: grayscale(0)
        > .interactable
            background-color: $color-brand-ultra-light