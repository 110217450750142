@use '../../variables.sass' as *
    
.InputDate
    position: relative
    > .Button
        opacity: 0.5
        position: absolute
        right: 0.75rem
        top: 0.75rem

.InputDateCard
    border-radius: 0.5rem
    background-color: $color-white
    box-shadow: 2px 2px 20px rgba(0,0,0, .1)
    width: fit-content
    user-select: none
    > .PageSwitcher
        display: flex
        justify-content: space-between
        align-items: center
        > div
            width: 50%
            display: flex   
            justify-content: space-between
            align-items: center 
            padding: 0.25rem 0.5rem
            &:first-child
                border-right: 1px solid $color-light-black-opacity
            &:hover
                background-color: $color-white
    > .Month
        display: flex
        flex-direction: column
        padding: 0.5rem
        > .Weekdays
            display: flex
            font-size: 0.5rem
            font-weight: bold 
            > div
                text-align: center
                width: 14.28%
                padding: 0.25rem 0
                
        > .Days
            max-width: 234px
            display: flex
            flex-wrap: wrap
            > div
                color: rgba($text-secondary-color, 0.5)
                text-align: center
                width: 14.28%
                padding: 0.5rem 0
                cursor: pointer
                position: relative
                &::after
                    content: '' 
                    position: absolute
                    top: -1px
                    left: -1px
                    border-radius: 2rem
                    width: 2rem
                    height: 2rem
                    display: none
                    border: 2px solid $primary-color  
                &:hover
                    &::after
                        display: block
                        border: 2px solid $color-light-black-opacity
                &.month
                    color: $text-primary-color
                &.today
                    color: $primary-color
                    &::after
                        display: block
                    &:hover
                        &::after
                            border: 2px solid $primary-color  
        &:hover
            background-color: $color-white
    &:hover
        background-color: #f8f8f8

                        