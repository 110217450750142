@use '../../variables' as *

.OverlayPositioned
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    &__Background
        width: 100%
        height: 100%
        &.blur
            background: rgba(0,0,0,.3)
            backdrop-filter: blur(2px)
    &__Floater
        opacity: 0
        position: absolute