@use '../../variables.sass' as *
    
.DatePicker
    display: flex
    align-itens: center
    justify-content: center
    > .ButtonDropdown
        margin: 0 1rem 0 0.5rem
    &__Handler
        display: flex
        align-itens: center
        justify-content: center
    &__Custom
        display: flex
        align-items: center
        gap: 0.5rem
        &__Field
            display: flex
            align-items: center
            > input
                border: 0
                font-family: Roboto
                padding: 0.5rem
                outline: 0
                border: 1px solid $color-light-black-opacity
                border-radius: 0.25rem
    &__Selector
        width: 204px
        &__Top
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0.825rem 1rem
        &__Bottom
            display: flex
            flex-wrap: wrap
            padding: 0.25rem 0
            > div
                text-align: center
                padding: 0.75rem 1rem
                width: 100%
                flex: 1
                font-size: 1rem
                cursor: pointer
                position: relative
                text-transform: capitalize
                &:hover
                    &::after
                        content: ''
                        width: calc(100% - 1rem)
                        height: calc(100% - 0.5rem)
                        top: 0.25rem
                        left: 0.5rem
                        border-radius: 0.25rem
                        position: absolute
                        box-shadow: inset 0 0 0 2px $color-light-black-opacity
                &.selected
                    &::after
                        content: ''
                        width: calc(100% - 1rem)
                        height: calc(100% - 0.5rem)
                        top: 0.25rem
                        left: 0.5rem
                        border-radius: 0.25rem
                        position: absolute
                        background-color: $color-light-black-opacity