@use '../../variables' as *

.Admin
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &__Logged
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        &__Header
            width: 100%
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0.5rem 1rem
            background-color: $color-background-gray
            border-bottom: 1px solid $color-light-black-opacity
            position: relative
            .LogoComp
                display: flex
                align-items: center
                font-weight: bold
                > .Logo
                    width: 3rem
                    margin-bottom: 0.25rem
                    margin-right: 0.25rem
            > .Tabs
                position: absolute
                bottom: -1px
                left: 50%
                width: fit-content
                transform: translateX(-50%)
            .User
                display: flex
                align-items: center
                gap: 1rem
                &__Photo
                    width: 2.25rem
                    height: 2.25rem
                    border-radius: 2rem

        &__Content
            width: 100%
            flex: 1
            min-height: 0
