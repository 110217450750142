@use '../../../../variables.sass' as *
    
.ConciliationCart
    position: sticky
    bottom: 1rem
    width: calc(100% - 2rem)
    height: fit-content
    margin: 0 1rem
    display: flex
    flex-direction: column
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2), 0 20px 40px 40px rgba(255, 255, 255, 1)
    border-radius: 0.5rem
    overflow: hidden
    background-color: $color-white
    &__Header
        background-color: $color-background-gray
        color: $text-primary-color
        font-weight: 400
        padding: 1rem
        > .name-comp
            display: flex
            width: calc(100% - 2.5rem)
            > div
                margin-right: 0.25rem
                min-width: fit-content
            > .ofx-label
                font-weight: 600
                margin-right: 0
                min-width: 0
    &__BillsList

    &__BottomInfo
        display: flex
        padding: 1rem
        gap: 1.5rem
        &__BottomNumber
            display: flex
            flex-direction: column
            align-items: flex-end
            > .caption
                color: $color-text-label
            > .body1
                &.negative
                    color: $color-status-error
        &__Buttons
            flex: 1
            display: flex
            justify-content: flex-end
    &.valid
        .ConciliationCart
            &__Header
                background-color: $color-brand-ultra-light
                color: $color-brand-dark
    &__Animation
        position: absolute
        width: 100%
        height: 100%
        background: $color-white
        top: 0
        left: 0
        z-index: 2
        display: flex
        justify-content: center
        align-items: center
        gap: 2rem
        &__1
            display: flex
            justify-content: center
            align-items: center
            animation: icon-left 0.4s
            z-index: 1
            > .Icon
                width: 2rem
                height: 2rem
        &__2
            display: flex
            justify-content: center
            align-items: center
            width: 3rem
            height: 3rem
            border-radius: 1.5rem
            background-color: $color-white
            z-index: 1
            &__Ripple
                width: 6rem
                height: 6rem
                border-radius: 6rem
                background-color: $accent-color
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                opacity: 0 
        &__3
            display: flex
            justify-content: center
            align-items: center
            animation: icon-right 0.4s 
            z-index: 1
            > .Icon
                width: 2rem
                height: 2rem
        &.done
            .ConciliationCart
                &__Animation
                    &__2
                        animation: bump 0.4s 
                        background-color: $accent-color
                        > .Icon
                            color: $color-white
                        &__Ripple
                            animation: ripple 0.4s 
                            animation-iteration-count: 1

    @keyframes icon-left
        0%
            transform: translateX(-50px)
        100%
            transform: translateX(0)
    @keyframes icon-right
        0%
            transform: translateX(50px)
        100%
            transform: translateX(0)
    @keyframes bump
        0%
            transform: scale(1)
        20%
            transform: scale(2)
        100%
            transform: scale(1)
    @keyframes ripple
        0%
            transform: translate(-50%, -50%) scale(1)
            opacity: 0    
        100%
            transform: translate(-50%, -50%) scale(30)   
            opacity: 0.5       
            
