@use '../../variables' as *

.NFeFiscal
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    &__Content
        flex: 1
    &__Submenu
        display: flex
        align-items: center
        height: 60px
        padding: 0.5rem

.TableActions
    display: flex
    justify-content: space-between
    margin-bottom: 1rem
    
