@use '../../variables' as *

.NFe
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    &__Content
        flex: 1
        display: flex
        flex-direction: column
    &__Submenu
        flex: 0
        min-height: 50px
        display: flex
        align-items: center
        height: 50px
        padding: 0.5rem
        background-color: $color-white
        > .Tabs
            margin: 0 auto
    
.Upsell
    background: $color-white
    border-radius: 1rem
    width: 90vw
    height: 90vh
    box-shadow: 2px 2px 20px rgba(0,0,0, .2)
    overflow: hidden
    position: relative