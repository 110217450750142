@use '../../variables.sass' as *
    
.ModuleEmptyState
    display: flex
    border: 2px dashed $color-light-black-opacity
    border-radius: 0.5rem
    align-items: center
    padding: 0 0.75rem
    gap: 0.75rem
    height: 3.25rem
    color: $text-secondary-color
    position: relative
    > .clickable
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 0
        border-radius: inherit
        cursor: pointer
    > .Icon
        color: $primary-color
        z-index: 1
        pointer-events: none
    > .body2
        z-index: 1
        pointer-events: none
