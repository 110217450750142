@use '../../variables' as *

.Roadmap
    display: flex
    overflow: auto
    width: 100%
    height: 100%
    user-select: none
    > .Controls
        position: absolute
        bottom: 2rem
        right: 2rem
        background-color: rgba($color-white, 0.1)
        backdrop-filter: blur(20px)
        z-index: 3
        user-select: all
        display: flex
        align-items: center
        border: 1px solid $color-light-black-opacity
        border-radius: 0.25rem
        > .group
            display: flex
            align-items: center
            padding: 0 0.5rem
            gap: 0.5rem
            > .Icon
                color: $color-light-black-opacity
            > .value
                user-select: none
                color: rgba($text-secondary-color, 0.5)
                font-weight: bold
                font-size: 0.875rem
                width: 40px
                text-align: center
            &:not(:last-child)
                border-right: 1px solid $color-light-black-opacity
                
    &__Left
        position: sticky
        left: 0
        min-width: 400px
        height: fit-content
        z-index: 2
        background-color: $color-white
        > .Header
            position: sticky
            top: 0
            display: flex
            flex-direction: column
            padding-top: 1rem
            background-color: $color-white
            border-bottom: 1px solid $color-light-black-opacity
            z-index: 2
            > .Tabs
                margin-left: 1rem
                border-bottom: 1px solid $color-light-black-opacity
            > .Header__Bottom
                background-color: $color-white
                background-color: $color-background-gray
                padding: 0.75rem
                height: calc(62px)
                display: flex
                gap: 1rem
                z-index: 3
                position: relative
                &::after
                    content: ''
                    position: absolute
                    width: 10px
                    height: calc(100vh - 132px)
                    background: linear-gradient(0.25turn, rgba(0,0,0, 0.1), rgba(0,0,0,0))
                    right: -10px
                    top: 0
                    z-index: 0
                > input
                    outline-color: $text-secondary-color
                    padding: 0.5rem
                    flex: 1 0
                    height: 36px
                    border-radius: 0.25rem
                    border: 1px solid $color-light-black-opacity
        > .Items
            position: relative
            z-index: 1
            background-color: $color-white
            > .Item
                height: 300px
    &__Right
        height: fit-content
        position: relative
        > .markers
            position: absolute
            display: flex
            // width: 2000px
            height: 100%
            cursor: grab
            > .marker
                min-height: 100%
                height: 100%
                width: 100% 
                border-right: 1px solid $color-light-black-opacity
        > .Header
            position: sticky
            top: 0
            left: 300px
            background-color: $color-white
            border-bottom: 1px solid $color-light-black-opacity
            z-index: 1
            > .Filters
                height: 58px
            > .quarters
                border-top: 1px solid $color-light-black-opacity
                display: flex
                // width: 2000px
                height: 63px
                > .quarter
                    width: 100%
                    display: flex
                    flex-direction: column
                    > .label
                        font-weight: bold
                        color: $text-secondary-color
                        font-size: 0.825rem
                        flex: 1
                        background-color: $color-white
                        background-color: $color-background-gray
                        padding: 0.75rem
                        text-align: center
                        border-right: 1px solid $color-light-black-opacity
                    > .months
                        display: flex
                        > div
                            font-weight: bold
                            color: rgba($text-secondary-color, 0.5)
                            font-size: 0.75rem
                            text-transform: uppercase
                            width: 100% 
                            padding: 0.25rem 1rem
                            text-align: center
                            background-color: $color-white
                            background-color: $color-background-gray
                            border-right: 1px solid $color-light-black-opacity
        > .Items
            position: relative
            > .Item
                height: 300px
            > .TodayTracker
                top: 0
                position: absolute
                width: 2px
                background-color: $accent-color
                height: 100%
                
.FiltersWindow
    border-radius: 0.5rem
    background-color: $color-white
    box-shadow: 2px 2px 20px rgba(0,0,0, 0.1)
    min-width: 350px
    user-select: none
    > .group
        display: flex
        flex-direction: column
        gap: 0.5rem
        padding: 1rem
        > .options
            display: flex
            flex-direction: column
            gap: 0.25rem
            &.horizontal
                flex-direction: row
                gap: 1rem