@use '../../../../variables' as *

.ViewNFe
    width: 100vw
    height: 100vh
    background-color: $color-white
    display: flex
    flex-direction: column
    &.popup
        width: 100%
        height: 100%
        position: relative
        > .Button
            position: absolute
            top: 1rem
            right: 1.5rem
    > .Header
        height: 75px
        padding: 0 2rem
        display: flex
        align-items: center
        justify-content: space-between
    &__Content
        display: flex
        height: 100%
        flex: 1
        min-height: 0
        > .Preview
            flex: 1
            border-right: 1px solid $color-light-black-opacity
            height: 100%
            display: flex
            flex-direction: column
            padding-top: 17px
            > .Tabs
                margin: 0 1rem
            > .PDFPreview
                flex: 1
                background-color: $color-light-black-opacity
        > .Tools
            width: 340px
            height: 100%
            display: flex
            flex-direction: column
            > .Header
                min-height: 0
                padding: 1.25rem
                height: 59px
                > .Subheader
                    display: flex
                    align-items: center
                    gap: 0.5rem
                    margin-bottom: 1rem
            > .Events
                padding: 1.25rem
                display: flex
                flex-direction: column
                flex: 1
                min-height: 0
                > .EventsList
                    margin-top: 1rem
                    display: flex
                    flex-direction: column
                    gap: 0.75rem
                    flex: 1
            > .Actions
                padding: 1.25rem
                display: flex
                flex-direction: column
                gap: 0.5rem
                min-height: 0
                height: fit-content

.EventCard
    border: 1px solid $color-light-black-opacity
    border-radius: 0.25rem
    padding: 0.5rem 0.75rem
    min-height: 2.5rem
    display: flex
    flex-direction: column
    gap: 0.25rem
    > .TimeHeader
        display: flex
        align-items: center
        justify-content: space-between
        color: $text-secondary-color
        margin-bottom: 0.5rem
    > .card
        flex: 1
        display: flex
        align-items: center
        justify-content: space-between
    > .body2
        color: $text-secondary-color
    > .Button
        margin: 0.75rem 0 0.25rem 0
        align-self: flex-start
    &.error
        border: 1px solid $color-status-error
        background-color: rgba($color-status-error-light, 0.3)
