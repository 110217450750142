@use '../../variables.sass' as *
    
.ModuleSelected
    display: flex
    background: linear-gradient(170deg, #FFF 34.92%, #999 345.29%)
    box-shadow: inset 4px 4px 8px rgba(#FFF, 0.5)
    border: 1px solid $color-light-black-opacity
    border-radius: 0.5rem
    align-items: center
    height: 3.25rem
    gap: 0.75rem
    padding: 0 1rem
    position: relative
    > .clickable
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 0
        border-radius: inherit
        cursor: pointer
    > .Icon 
        pointer-events: none
        z-index: 1
    > .left
        pointer-events: none
        z-index: 1
        flex: 1
        > .title
            color: $text-primary-color
            font-weight: 500
            line-height: 1rem
        > .subtitle
            color: $text-secondary-color
            line-height: 1rem
    > .right
        z-index: 1
        display: flex
        gap: 1rem
        align-items: center
    &:hover
        box-shadow: inset 4px 4px 8px rgba(#FFF, 0.5), 0 0 0 2px rgba(0,0,0, .1)
        