@use '../../variables.sass' as *
    
.LCPDTopBar
    width: calc(100% - 2rem)
    min-height: 52px
    height: 52px
    padding: 0 1rem
    background: $primary-color
    box-shadow: 0 2px 5px rgba(0,0,0, 0.2)
    display: flex
    align-items: center
    justify-content: space-between
    > .Left
        display: flex
        gap: 1rem
        align-items: center
        > .Divider
            background: white
            height: 24px