@use '../../variables' as *

.CodeValidation
    flex: 1
    display: flex
    flex-direction: column
    > .Box    
        flex: 1
        display: flex
        flex-direction: column
        > .content
            padding: 2rem 0
            display: flex
            flex-direction: column
            gap: 1rem 
            flex: 1
            > .VariableContent
                display: flex
                flex-direction: column
                gap: 1rem 
                flex: 1
            > .ActionContent
                display: flex
                flex-direction: column
                gap: 1rem 
