@use '../../variables.sass' as *
    
.Button
    border: 0
    padding: 0.5rem 1rem
    border-radius: 0.25rem
    font-family: Roboto
    text-transform: uppercase
    font-weight: 500
    cursor: pointer
    background-color: $primary-color
    color: $color-white
    user-select: none
    display: flex
    align-items: center
    white-space: nowrap
    height: 36px
    > .label
        display: flex
        gap: 0.25rem
        align-items: center
        > .Icon
            margin-right: 0.25rem
            color: $color-white
        > .Counter
            margin-right: 0.25rem
    > .Loader
        position: absolute
        color: $color-white
    &.small
        font-size: .825rem
        padding: 0.25rem .75rem
        > .label
            gap: 0.5rem
            > .Icon
                margin-right: 0.5rem
            > .Counter
                margin-right: 0.5rem
    &.large
        border-radius: 0.5rem
        font-size: 1rem
        padding: 1rem 0.75rem
    &.full
        width: 100%
        text-align: center
        justify-content: center
    &.default
    &.outline
        background-color: transparent
        box-shadow: inset 0 0 0 1px $primary-color
        color: $primary-color
        > .label
            > .Icon
                color: $primary-color
    &.outline-grey
        background-color: transparent
        box-shadow: inset 0 0 0 1px $text-primary-color
        color: $text-primary-color
        > .label
            > .Icon
                color: $text-primary-color
    &.text
        background-color: transparent
        color: $primary-color
        > .label
            > .Icon
                color: $primary-color
    &.text-grey
        background-color: transparent
        color: $text-primary-color
        > .label
            > .Icon
                color: $text-primary-color
    &.icon
        background-color: transparent
        padding: 0
        > .label
            > .Icon
                margin-right: 0
                color: $text-primary-color
    &.loading
        > .label
            opacity: 0

    &:disabled
        color: $text-primary-color
        background-color: $color-light-black-opacity
        pointer-events: none
        opacity: 0.38
        cursor: not-allowed
        box-shadow: none
        > .label
            > .Icon
                color: $text-primary-color
        