@use '../../variables.sass' as *
    
.Radio
    padding: 0.25rem 0
    cursor: pointer
    user-select: none
    width: fit-content
    > input[type="radio"]
        display: none
        &:hover+label
            .Radio__Circle
                box-shadow: inset 0 0 0 2px $text-primary-color
                &::after
                    background-color: $text-secondary-color
                    width: 0.5rem
                    height: 0.5rem
                    opacity: 1
        &:checked+label
            .Radio__Circle
                box-shadow: inset 0 0 0 2px $primary-color
                &::after
                    background-color: $primary-color
                    width: 0.625rem
                    height: 0.625rem
                    opacity: 1

    > label
        display: flex
        align-items: center
        gap: 0.5rem
        cursor: pointer
    &__Circle
        width: 1.25rem
        height: 1.25rem
        border-radius: 0.75rem
        box-shadow: inset 0 0 0 2px $text-secondary-color
        position: relative
        &::after
            content: ''
            position: absolute
            width: 0
            height: 0
            opacity: 0
            border-radius: 0.3125rem
            background-color: $text-secondary-color
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
    &__Label
        color: $text-primary-color