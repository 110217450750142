@use '../../variables' as *

.JTBDOptions
    background-color: $color-white
    box-shadow: 2px 2px 20px rgba(0,0,0, 0.1)
    border-radius: 0.5rem
    max-width: 350px
    max-height: 450px
    user-select: none
    > .group
        padding: 1rem 0
        > .description
            padding: 1rem 1rem 0.5rem 1rem
            font-size: 0.825rem
            margin-bottom: 0.5rem
            color: $text-secondary-color
        > .option
            padding: 0 0.5rem 1rem 1.5rem
            display: flex
            align-items: center
            > .description
                font-size: 0.825rem
        
.JTBDSelector
    user-select: none
    > .field
        cursor: pointer
        padding: 0.5rem
        box-shadow: inset 0 0 0 2px $color-light-black-opacity
        border-radius: 0.25rem
        font-size: 1rem
        color: $primary-color
        font-weight: bold
        display: flex
        align-items: center
        gap: 0.5rem
        &:hover
            box-shadow: inset 0 0 0 2px $primary-color
            background-color: $color-brand-ultra-light