@use '../../variables.sass' as *
    
.SidebarContent
    display: flex
    width: 100%
    height: 100%
    > .SidebarWrapper
        width: 270px
        height: calc(100vh - 60px)
        overflow: hidden
        position: relative
        > .Sidebar
            min-width: 270px
            width: 100%
            height: 100%
            padding: 3rem 2rem
            display: flex
            flex-direction: column
            position: relative
            background: linear-gradient(205deg, #ffffff75 0%, #a6a6a643 100%)
            > .ButtonDropdown
                position: absolute
                top: 1rem
                right: 1rem
            > .Icon
                width: 18rem
                height: 18rem
                position: absolute
                right: -2rem
                bottom: 0rem  
                color: #a6a6a627
            > .Header
                margin-bottom: 4rem
                > .h3
                    font-weight: bold
                    color: $text-primary-color
                > .body
                    color: $text-secondary-color
        &::after
            content: ''
            position: absolute
            right: 0
            top: 0
            width: 0.5rem
            height: 100%
            background: linear-gradient(90deg, #ffffff00 0%, #a6a6a62d 100%)
    > .Sidecontent
        width: 100%
        padding: 2rem
        flex: 1
        > .Bar
            display: flex
            justify-content: space-between
            > .Title
                display: flex
                align-items: center
                gap: 1rem
                margin-bottom: 1rem
            > .h4
                margin-bottom: 1rem
    &.closed
        > .SidebarWrapper
            width: 0
            transition-property: width
            > .Sidebar
                transform: translateX(-3rem)
