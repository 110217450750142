@use '../../variables' as *

.NewLogin
    width: 100%
    height: 100%
    > .Box
        margin: 0 auto
        background: rgba($color-white, 0.9)
        max-width: 550px    
        min-height: calc(100% - 4rem)
        border-radius: 1rem 1rem 0 0 
        backdrop-filter: blur(10px)
        padding: 2rem 
        display: flex
        flex-direction: column
        .Header
            margin-bottom: 2rem
            &__Top
                display: flex
                justify-content: space-between
                align-items: flex-end
                padding-bottom: 1rem
                .AegroLogo
                    width: auto
                    height: 37px
    > .LoaderWrapper
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        top: 0
        left: 0
        background-color: rgba($color-white, 0.8)
        > .Loader
            width: 2rem
            height: 2rem