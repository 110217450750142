@use '../../variables' as *

.CodeSend
    flex: 1
    display: flex
    flex-direction: column
    > .Box   
        flex: 1
        display: flex
        flex-direction: column
        > .content
            padding: 2rem 0
            display: flex
            flex-direction: column
            gap: 1rem 
            flex: 1
            .section
                display: flex
                align-items: center
                padding: 0.5rem 0
                color: $color-gray-opacity
                > span
                    padding: 0 0.5rem
                    font-size: 0.75rem
            > .VariableContent
                display: flex
                flex-direction: column
                gap: 1rem 
                flex: 1
            > .ActionContent
                display: flex
                flex-direction: column
                gap: 1rem 
    .SendOption
        display: flex
        margin-bottom: 0.5rem
        cursor: pointer
        user-select: none
        &__Info
            margin-top: 2px
            &__Title
                display: flex
                align-items: center
                gap: 0.5rem
                margin-bottom: 0.25rem
                > .Icon
                    color: $text-primary-color
            > .body2
                color: $color-text-label