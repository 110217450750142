@use '../../variables' as *

.OFXConcWrapper
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
.OFXConc
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    &__Header
        // width: 100%
        min-width: 70%
        margin: 0 auto
        &__Top
            height: 64px
            display: flex
            align-items: center
            justify-content: space-between
            padding-bottom: 1rem
    &__CompactHeader
        display: none

@media only screen and (max-height: 750px)
    .OFXConc
        &__Header
            display: none
        &__CompactHeader
            display: flex
            align-items: center
            justify-content: space-between
            padding: 1rem 1rem
            > .h6
                margin-right: 2rem
            > .Tabs
                margin-bottom: -1rem
                width: fit-content