@use '../../variables.sass' as *
    
.TextArea
    > textarea
        width: 100%
        resize: vertical
        font-family: 'Roboto'
        padding: 0.5rem
        color: $text-primary-color
        &:disabled
            color: $color-gray-opacity
    > .infos
        width: 100%
        display: flex
        justify-content: flex-end
        color: $text-secondary-color
