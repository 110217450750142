@use '../../variables.sass' as *
    
.Dialog
    display: flex
    flex-direction: column
    gap: 2rem
    padding: 2rem
    &__Messages
        display: flex
        flex-direction: column
        gap: 1rem
    &__Actions
        display: flex
        justify-content: flex-end
        gap: 1rem
.fix
    max-height: inherit
    overflow: auto !important