.HStack
    display: flex
    &.fillWidth
        width: 100%
        > *
            flex: 1
    &.fillHeight
        height: 100%
        > *
            flex: 1
    &.none
        gap: 0rem
    &.compact
        gap: 0.5rem
    &.normal
        gap: 1rem
    &.spaced
        gap: 1.5rem