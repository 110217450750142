@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

* {
  box-sizing: border-box
}

body, html, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: 'Roboto', sans-serif;
}

.transition-in-out {
  transition: all 0.2s cubic-bezier(0.86,0,0.07,1);
}
.transition-in-out::after {
  transition: all 0.2s cubic-bezier(0.86,0,0.07,1);
}
.transition-in-out::before {
  transition: all 0.2s cubic-bezier(0.86,0,0.07,1);
}


.transition-out {
  transition: all 0.2s cubic-bezier(0.23,1,0.32,1);
}
.transition-out::after {
  transition: all 0.2s cubic-bezier(0.23,1,0.32,1);
}
.transition-out:before {
  transition: all 0.2s cubic-bezier(0.23,1,0.32,1);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrollable {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollable::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
.scrollable::-webkit-scrollbar-thumb {
  border-left: 4px solid rgba(255,255,255,0);
}
.scrollable:hover::-webkit-scrollbar-thumb {
  border-left: 4px solid rgba(0,0,0, 0.2);
}

.invisible-scroll {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.invisible-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

@media screen and ( max-width: 767px ) {
  .scrollable::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scrollable::-webkit-scrollbar-thumb {
      border-left: 0 solid rgba(255,255,255,0);
    }
  .scrollable:hover::-webkit-scrollbar-thumb {
    border-left: 0 solid rgba(0,0,0, 0.2);
  }
}

.h1, h1 {
  font-family: Roboto;
  font-size: 112px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.5px;
}

.h2, h2 {
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
}

.h3, h3 {
  font-family: Roboto;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h4, h4 {
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 117.647% */
  letter-spacing: 0.25px;
}

.h5, h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
}

.h6, h6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.25px;
}

.body1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.5px;
}

.body2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.subtitle1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.subtitle2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.overline {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}