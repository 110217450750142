@use '../../variables' as *

.AccountCreate
    flex: 1
    display: flex
    flex-direction: column
    > .Box   
        flex: 1
        display: flex
        flex-direction: column 
        > .content
            padding: 2rem 0
            display: flex
            flex-direction: column
            gap: 1rem
            flex: 1
            .form_line
                display: flex
                width: 100%
                gap: 1rem
                > div
                    width: 100%
            > .social
                display: flex
                gap: 0.5rem
            > .section
                display: flex
                align-items: center
                padding: 0.5rem 0
                color: $color-gray-opacity
                > span
                    padding: 0 0.5rem
                    font-size: 0.75rem
            > .fineline
                padding-top: 1rem
                color: $color-gray-opacity
                line-height: 1rem
                font-size: 0.7rem
                > a
                    color: $primary-color
            > .VariableContent
                display: flex
                flex-direction: column
                gap: 1rem 
                flex: 1
            > .ActionContent
                display: flex
                flex-direction: column
                gap: 1rem 
                    
@media only screen and (max-width: 550px) 
    .AccountCreate
        > .Box    
            > .content
                .form_line
                    display: flex
                    flex-direction: column