@use '../../variables.sass' as *
    
.ModuleListItem
    display: flex
    box-shadow: inset 4px 4px 8px rgba(#FFF, 0.5)
    border-radius: 0.5rem
    align-items: center
    height: 3.25rem
    gap: 0.75rem
    padding: 1.5rem 1rem
    position: relative
    cursor: pointer
    > .Icon 
        pointer-events: none
        z-index: 1
    > .left
        pointer-events: none
        z-index: 1
        flex: 1
        > .title
            color: $text-primary-color
            font-weight: 500
            line-height: 1.125rem
        > .subtitle
            color: $text-secondary-color
            line-height: 1.125rem
    &:hover
        background-color: $color-background-gray
        