@use '../../variables.sass' as *
    
.ButtonMulti
    border: 0
    padding: 0.5rem 1rem
    border-radius: 0.25rem
    font-family: Roboto
    text-transform: uppercase
    font-weight: 500
    cursor: pointer
    box-shadow: 1px 1px 5px rgba(0,0,0, .2)
    background-color: $color-white
    color: $primary-color
    user-select: none
    display: flex
    align-items: center
    white-space: nowrap
    height: 36px
    > .label
        display: flex
        gap: 0.5rem
        align-items: center
        > .Icon
            color: $primary-color
        > .Counter
            margin-right: 0.5rem
    > .Loader
        position: absolute
        color: $color-white

    &.more
        color: $text-primary-color
        > .label
            flex-direction: row-reverse
            margin: 0 auto
            > .Icon
                color: $text-primary-color


    &.small
        font-size: .825rem
        padding: 0.25rem .5rem
    &.large
        border-radius: 0.5rem
        font-size: 1rem
        padding: 1rem 0.75rem
    &.full
        text-align: center
        justify-content: center
    &.loading
        > .label
            opacity: 0

    &:disabled
        color: $text-primary-color
        background-color: $color-light-black-opacity
        pointer-events: none
        opacity: 0.38
        cursor: not-allowed
        box-shadow: none
        > .Icon
            color: $text-primary-color
        