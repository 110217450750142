@use '../../variables' as *

.Deliverable
    border-bottom: 1px solid $color-light-black-opacity
    height: 100px
    position: relative
    &__Title
        padding: 1rem
    &__Gantt
        > .upstream, > .downstream
            background: $color-white
            width: fit-content
            height: calc(100% - 1rem)
            padding: 0.5rem 0.5rem 0.5rem 0.75rem
            border-radius: 0.5rem
            position: absolute
            top: 0.5rem
            left: 0.5rem
            display: flex
            flex-direction: column
            justify-content: flex-end
            font-size: 0.825rem
            font-weight: bold
            box-shadow: 2px 2px 10px rgba(0,0,0, 0.1), inset 0 0 0 1px rgba($color-light-black-opacity, 0.1)
            overflow: hidden
            cursor: pointer
            > .statusbar
                width: 4px
                height: 100%
                position: absolute
                top: 0
                left: 0
                &.backlog
                    background-color: $color-dark-black-opacity
                &.production
                    background-color: $accent-color
                &.delivered
                    background-color: $primary-color
                &.canceled                    
                    background-color: $color-status-error
            > .label
                text-wrap: nowrap
            > .stage
                font-weight: 400
                font-style: italic
                opacity: 0.5
                font-size: 0.75rem
                text-wrap: nowrap
            &:hover
                opacity: 0.8
                box-shadow: 2px 2px 10px rgba(0,0,0, 0.1), inset 0 0 0 3px rgba($color-light-black-opacity, 0.1)
        > .downstream
            background: $color-background-gray
        &.intersect
            > .upstream
                height: calc(50% - .5rem)
            > .downstream
                top: calc(50%)
                height: calc(50% - .5rem)
        > .clickBg
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            cursor: grab