@use '../../variables' as *

.NFeHome
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    > .Banners
        display: flex
        flex-wrap: wrap
        gap: 1rem
        max-width: 1200px
        margin: 0 auto
        padding: 1rem
        width: 100%
        > .Full
            width: 100%
            height: 400px
            padding: 2rem
            display: flex
            flex-direction: row
            justify-content: space-around
            align-items: center
            position: relative
            > img
                position: absolute
                top: 0
                left: 0
                width: 100%
                transform: translate(-30%, -30%)
                pointer-events: none
            > .Diferenciais
                display: flex
                flex-direction: column
                gap: 0.5rem
                > div
                    display: flex
                    align-items: center
                    gap: 0.5rem
                    font-size: 1.125rem
                    font-weight: 500
                    > .Icon
                        color: $primary-color
        > .Banner
            background-color: $color-white
            border-radius: 0.5rem
            box-shadow: inset 0 0 0 1px rgba(0,0,0, .1)
            &.Main
                margin: 0 auto
                width: 80%
                height: 200px
                padding: 2rem
                display: flex
                justify-content: space-between
                align-items: flex-end
                > .left
                    .h4
                        font-weight: 500
                        span
                            color: $color-brand-dark
                > .right
                    display: flex
                    flex-direction: column
                    justify-content: flex-end
                    align-items: flex-end
                    gap: 1rem
                    a 
                        color: $primary-color
                    
            &.Tip
                min-width: 300px
                width: 33%
                flex: 1
                height: 250px
                padding: 2rem
                display: flex
                flex-direction: column
                justify-content: flex-end