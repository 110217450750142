@use '../../variables.sass' as *
    
.ModuleList
    background-color: $color-white
    box-shadow: 2px 2px 20px rgba(0,0,0, .2)
    border-radius: 0.5rem
    min-width: 400px
    height: 350px
    display: flex
    flex-direction: column
    overflow: hidden
    > .SearchBox
        position: relative
        padding: 0.25rem
        > input
            background-color: $color-background-gray
            color: $text-primary-color
            border: 0
            padding: 0.5rem 1rem 0.5rem 2rem
            outline: $color-dark-black-opacity
            border-radius: 0.25rem
            font-size: 1rem
            width: 100%
            &::placeholder
                color: $text-secondary-color
        > .Icon
            top: 0.75rem
            left: 0.75rem
            position: absolute
    > .ItemsList
        flex: 1
        > .Wrapper
            display: flex
            flex-direction: column
            padding: 1rem
            gap: 0.75rem
    > .AddButton
        display: flex
        align-items: center
        gap: 0.5rem
        padding: 1rem
        cursor: pointer
        &:hover
            background-color: $color-background-gray