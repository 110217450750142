@use '../../variables.sass' as *
    
.FlashSwitch
    width: 2.5rem
    max-width: 2.5rem
    min-width: 2.5rem
    height: 1.5rem
    box-shadow: inset 0 0 10px rgba(0,0,0,0.1)
    border-radius: 2rem
    position: relative
    cursor: pointer
    &__Handler
        width: 1.5rem
        height: 1.5rem
        border-radius: 1rem
        box-shadow: 0 0 10px rgba(0,0,0,0.1), inset 0 0 0 1px $color-white
        background-color: $color-white
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        transform: translateX(0)
        > .Icon
            transform: translateY(1px)
    &.active
        .FlashSwitch
            &__Handler
                transform: translateX(1rem)
                background-color: $primary-color
                box-shadow: 0 0 10px rgba($primary-color,0.5), 0 0 0 2px $color-brand-light
                > .Icon
                    color: $color-white