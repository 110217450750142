@use '../../variables.sass' as *

.FilterTag
    background-color: $color-light-black-opacity
    color: $text-primary-color
    border-radius: 1rem
    font-size: 0.825rem
    padding: 0.25rem 0.75rem
    width: fit-content
    height: 1.5rem
    display: flex
    align-items: center
    gap: 0.5rem
    &__Action
        height: 1rem
        cursor: pointer
        display: flex
        align-items: center
        > div
            margin-right: 0.25rem
    &__Label
        font-weight: 500
    &__RangeOptions
        padding: 1rem
        display: flex
        align-items: center
        gap: 0.5rem
        &__Field
            > label
                text-transform: uppercase
                font-size: 0.75rem
            > input
                border: 0
                font-family: Roboto
                padding: 0.5rem
                outline: 0
                border: 1px solid $color-light-black-opacity
                border-radius: 0.25rem