@use '../../variables' as *

.NFeTeam
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    &__Content
        flex: 1
    .Profiles
        margin: 1rem 0 2rem 0
        display: flex
        flex-wrap: wrap
        gap: 1rem
        .Profile
            display: flex
            flex-direction: column
            align-items: center
            border: 2px solid $color-light-black-opacity
            width: fit-content
            padding: 1.5rem 2rem
            border-radius: 0.5rem
            &__User
                width: 4rem
                height: 4rem
                border-radius: 50%
                background-color: $primary-color
                margin-bottom: 1rem
                display: flex
                justify-content: center
                align-items: center
                font-size: 2rem
                color: rgba($color-white, .4)
            &__Name
                color: $text-primary-color
                font-weight: 500
            &__Email
                color: $text-primary-color
            &__Roles
                color: $text-secondary-color