@use '../../variables.sass' as *
    
.StructurePane
    width: 100%
    height: 100%
    flex: 1
    min-height: 0
    position: relative
    overflow: hidden
    > .Content
        width: 100%
        height: 100%
        z-index: 0
        transform: translate(0,0) scale(1)
        transition-duration: .6s
    > .Pane
        position: absolute
        background-color: $color-white
        z-index: 1
        box-shadow: 2px 2px 20px rgba(0,0,0, .1)
        transition-duration: .4s
        > .Button
            position: absolute
            top: 1rem
            right: 2rem
    &.opened
        > .Content
            pointer-events: none
    &.bottom
        > .Pane
            border-radius: 1rem 1rem 0 0 
            top: 5%
            left: 5%
            width: 90%
            height: 100%
            transform: translate(0, 100%)
        &.opened
            > .Content
                // transform: translate(0, -5%)
                min-height: 105%
                filter: brightness(0.8)
            > .Pane
                transform: translate(0, 0)
    &.right
        > .Pane
            border-radius: 0
            top: 0
            right: 0
            width: 80%
            height: 100%
            transform: translate(100%, 0)
        &.opened
            > .Content
                transform: translate(-5%, 0)
                width: 105%
                filter: brightness(0.8)
            > .Pane
                transform: translate(0, 0)