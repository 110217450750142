@use '../../variables' as *

.Gallery
    width: 100% 
    position: relative
    overflow: hidden
    border-radius: 0.5rem
    z-index: 0
    &__Controls
        padding: 1rem
        width: 100%
        height: 100%
        display: flex
        justify-content: space-between
        align-items: center
        position: absolute
        top: 0
        left: 0
        z-index: 1
        > .open
            cursor: pointer
            width: 80%
            height: 100%
    &__Pictures
        display: flex
        > div
            width: 100%
            height: 100%
            position: relative
            > img
                width: 100%
                height: 100%
                object-fit: contain
            > div
                position: absolute
                bottom: 1rem
                left: 50%
                transform: translateX(-50%)
                width: fit-content
                padding: 0.5rem 1rem
                border-radius: 1rem
                color: $color-white
                text-align: center
                background-color: $color-dark-black-opacity
    &.fullscreen
        z-index: 1
        margin: auto
        max-width: 90vw
        max-height: 90vh
        .Gallery__Controls
            > .close
                position: absolute
                top: 0.5rem
                right: 0.5rem
                background-color: $color-dark-black-opacity
                border-radius: 50%
                width: 2rem
                height: 2rem
                display: flex
                justify-content: center
                align-items: center
                > .Button
                    .Icon
                        color: $color-white
            > .arrow-wrapper
                background-color: $color-dark-black-opacity
                border-radius: 50%
                width: 2rem
                height: 2rem
                display: flex
                justify-content: center
                align-items: center
                > .Button
                    .Icon
                        color: $color-white
        .Gallery__Pictures            
            max-height: 90vh
            > div
                width: 100%
                height: auto