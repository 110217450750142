@use '../../variables.sass' as *
    
.ResizableContent
    display: flex
    width: 100%
    height: 100%
    > .ContentA
        height: 100%
        position: relative
        overflow: hidden
    > .ContentB
        flex: 1
        height: 100%
        position: relative
        overflow: hidden
    > .Handler
        width: 5px
        height: 100%
        cursor: ew-resize
        position: relative
        &::after
            content: '' 
            height: 100%
            width: 1px
            background-color: $color-light-black-opacity
            position: absolute
            top: 0
            left: 2px
        &:hover
            &::after
                width: 3px
                background-color: $color-light-black-opacity
                left: 1px
            
