@use '../../variables' as *

.DeliverableDetails
    background-color: $color-white
    width: 80vw
    height: 90vh
    border-radius: 0.75rem
    box-shadow: 4px 4px 20px rgba(0,0,0, 0.2)
    display: flex
    flex-direction: column
    overflow: hidden
    > .Header
        padding: 2rem 1rem 0 2rem
        border-bottom: 1px solid $color-light-black-opacity
        position: relative
        > .team
            color: $text-primary-color
        > .line
            display: flex
            align-items: center
            margin: 0.5rem 0
            gap: 1rem
            > .name
                font-size: 1.5rem
                letter-spacing: 0
            > .status
                text-transform: uppercase
                font-size: 0.75rem
                font-weight: bold
                padding: 0.25rem 0.5rem 
                border-radius: 1rem
                &.backlog
                    box-shadow: 0 0 10px rgba($text-secondary-color, 0.5), inset 0 0 0 1px rgba($text-primary-color, 0.2)
                    background-color: $color-background-gray
                    color: $text-primary-color
                &.production
                    box-shadow: 0 0 10px rgba($accent-color, 0.5), inset 0 0 0 1px rgba($accent-color-dark, 0.2)
                    background-color: $accent-color-ultra-light
                    color: $accent-color-dark
                &.delivered
                    box-shadow: 0 0 10px rgba($color-brand-light, 0.5), inset 0 0 0 1px rgba($color-brand-dark, 0.2)
                    background-color: $color-brand-ultra-light
                    color: $color-brand-dark
                &.canceled
                    box-shadow: 0 0 10px rgba($color-status-error, 0.5), inset 0 0 0 1px rgba($color-status-error, 0.2)
                    background-color: rgba($color-status-error, 0.1)
                    color: $color-status-error
            > .duration
                color: $text-secondary-color
            > .platform
                display: flex
                align-items: center
                gap: 0.5rem
        > .Button
            position: absolute
            top: 1rem
            right: 1.5rem
        > .Tabs
            margin-top: 1rem
    .Content
        display: flex
        height: 100%
        flex: 1
        &__Left
            width: 50%
            flex: 1
            padding: 2rem
            .description
                color: $text-secondary-color
            .krs, .jobstobedone
                margin-bottom: 1.5rem
            .overline
                margin-bottom: 0.5rem
                color: $text-primary-color
            .JTBDItem
                padding: 0.5rem 0.75rem
                border-radius: 0.25rem
                font-size: 0.825rem
                color: $accent-color-dark
                background-color: $accent-color-ultra-light
                display: flex
                gap: 0.5rem
                align-items: center
                margin-bottom: 1rem
                > .Icon
                    color: $accent-color-dark
                    min-width: 1rem
            .LinkItem
                padding: 0.25rem 0
                font-size: 0.825rem
                color: $accent-color
                display: flex
                gap: 0.5rem
                align-items: center
                margin-bottom: 1rem
                text-transform: capitalize
                > .Icon
                    color: $accent-color
                    min-width: 1rem
        &__Right
            width: 50%
            flex: 1
            padding: 2rem
            > .disclaimer
                margin-top: 1rem
                display: flex
                font-size: 0.75rem
                padding: 0.5rem
                gap: 0.5rem
                border-radius: 0.25rem
                border: 1px solid rgba($color-status-warning, 0.2)
                background-color: rgba($color-status-warning, 0.1)
                color: $color-dark-black-opacity
        > .FigmaFrame
            border: 0
            width: 100%
            height: 100%
            z-index: 1
        > .Loader
            position: absolute
            top: 50%
            left: 50%
            