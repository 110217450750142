@use '../../variables.sass' as *
    
.Counter
    width: fit-content
    height: fit-content
    min-width: 1.5rem
    min-height: 1.5rem
    border-radius: .75rem
    font-size: 1rem
    font-weight: bold
    background-color: $color-brand-ultra-light
    color: $color-brand-dark
    position: relative
    display: flex
    justify-content: center
    align-items: center
    > div
        padding: 2px 4px
        letter-spacing: -1px
    &.small
        min-width: 1.25rem
        min-height: 1.25rem
        border-radius: 1rem
        font-size: 0.825rem
    &.grey
        background-color: rgba($text-secondary-color, 0.1)
        color: $text-secondary-color
    &.warning
        background-color: rgba($color-status-warning, 0.1)
        color: $color-status-warning
    &.error
        background-color: rgba($color-status-error, 0.1)
        color: $color-status-error
    &.animate
        transform: scale(2)