@use '../../variables' as *

.OFXData
    width: 100%
    height: 100%
    &__OFXCard, &__BILLCard
        width: calc(100% - 1rem)
        border-radius: 0.5rem
        overflow: hidden
        margin: 0 0.5rem
        font-size: 0.85rem
        &__Header
            display: flex
            align-items: center
            gap: 1rem
            padding: 0.5rem 1rem
            border-bottom: 1px solid $color-light-black-opacity
            font-size: 0.85rem
            > .transfer
                flex: 1
                // width: 100%
                display: flex
                align-items: center
                > div
                    width: 50%
                > .Icon
                    width: 24px
                    min-width: 24px
                    margin-right: 1rem
                    color: $primary-color
                &.negative
                    > .Icon
                        color: $color-status-error

            > .Icon
                min-width: 24px
                height: 24px
            > .installment
                width: 80px
            > .date
                width: 100px
                color: $text-secondary-color
            > .value   
                min-width: 100px        
                text-align: right
                color: $primary-color
                &.negative
                    color: $color-status-error
            > .ellipsis
                width: 100%
        &__Content
            width: 100%
            padding: 1rem
            .FieldLine
                width: 100%
                display: flex
            .Field
                width: 100%
                display: flex
                flex-direction: column
                > .ButtonDropdown
                    width: 100%
                    margin-bottom: 1rem
                > label
                    color: $text-secondary-color
                    margin-bottom: 0.25rem
                > input
                    padding: 0.5rem
                    margin-bottom: 1rem
                    border: 1px solid $color-light-black-opacity
                    width: calc(100% - 4rem)
            
    &__List
        > .h6
            position: sticky
            top: 0
            z-index: 1
            background: $color-white
            padding: 1rem
            display: flex
            justify-content: space-between
            border-bottom: 1px solid $color-light-black-opacity
            margin-bottom: 2rem
            
.ModalHeader
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 1.5rem
    font-weight: 500
    padding: 1rem
    border-bottom: 1px solid $color-light-black-opacity