@use '../../variables' as *

.SimulIR
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    > .LCPDTopBar
        z-index: 1
    &__Content
        display: flex
        height: 100%
        &__Left
            width: 300px
            height: 100%
            padding: 1.5rem
            display: flex
            flex-direction: column
            gap: 2rem
            &__Section
                display: flex
                flex-direction: column
                gap: .5rem
                > .overline
                    margin-bottom: 1rem
        &__Right
            background-color: $color-background-gray
            width: 100%
            height: 100%
            .h5
                margin-bottom: 2rem
                display: flex
                justify-content: space-between
            > .Header
                padding: 2rem 2rem 0
                position: relative
                > .Tabs
                    position: sticky
                    top: 100px
                > .subtitle
                    color: $text-secondary-color
                    font-size: 1.5rem
                    margin-bottom: 2rem
            > .Content
                padding: 2rem 2rem 5rem 2rem
                max-width: 1200px
                margin: 0 auto
                > .Toolbar
                    display: flex
                    justify-content: space-between
                    > .period
                        background-color: $color-white
                        display: flex
                        padding: 0.5rem 1rem
                        border-radius: 2rem
                        font-size: 0.75rem
                        align-items: center
                        gap: 0.5rem
                    > .actions
                        display: flex
                        gap: 2rem
                > .Numbers
                    display: flex
                    align-items: center
                    justify-content: space-around
                    padding: 2rem 4rem
                    height: 130px
                > .Cards
                    display: grid
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
                    grid-gap: 2rem
                .GraphContainer
                    display: flex
                    justify-content: space-evenly
                    gap: 2rem
                    height: 300px
                    > div
                        flex: 1
                    > .Numbers
                        flex: 0
                        min-width: 300px
                        display: flex
                        flex-direction: column
                        gap: 2rem
                        justify-content: center
                .SimulContainer
                    margin-top: 3rem
                    display: flex
                    justify-content: space-evenly
                    gap: 2rem

.NumberItem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 0.5rem
    max-width: 200px
    &__Label
        color: $text-secondary-color
        text-align: center
    &__Number
        font-size: .85rem
        text-align: center
        line-height: 0.95rem
        opacity: 0.8
        > span
            font-size: 0.65rem
            color: $text-secondary-color
        &.active
            transform: scale(1.1)
            opacity: 1
            font-weight: bold
            > span
                font-weight: normal

    &.red
        .NumberItem
            &__Label
                color: $color-status-error
    &.blue
        .NumberItem
            &__Label
                color: $accent-color

.PersonItem
    display: flex
    align-items: center
    gap: 0.5rem
    padding: 0.25rem
    border-radius: 1rem 0 0 1rem
    width: calc(100% + 2rem)
    margin-bottom: 0.25rem
    cursor: pointer
    > .PersonIcon
        background-color: $primary-color
        color: $color-white
        width: 2rem
        height: 2rem
        border-radius: 1rem
        display: flex
        justify-content: center
        align-items: center
    &:last-child
        > .PersonIcon
            background-color: transparent
            color: $text-primary-color
            box-shadow: inset 0 0 0 2px $text-primary-color
    &.selected
        background-color: $color-background-gray

.MonthItem
    padding: 0 1rem
    border-radius: 1rem
    border: 1px solid $color-black-opacity
    > .Header
        padding: 1rem 0
        display: flex
        justify-content: space-between
        > div
            align-items: center
            gap: 0.5rem
            display: flex
    > .Content
        height: 100px
        padding: 1rem
        display: flex
        justify-content: center
        gap: 1rem
        &.empty
            align-items: center
            flex-direction: column
    &.fill
        background-color: $color-white
        border: 0