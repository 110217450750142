@use '../../variables.sass' as *
    
.Icon
    display: block
    width: 1.5rem
    height: 1.5rem
    color: $material-grey-icon
    > path
        fill: currentColor
    &.small
        width: 1rem
        height: 1rem