@use '../../variables.sass' as *
    
.Checkbox
    padding: 0.25rem 0
    cursor: pointer
    user-select: none
    width: fit-content
    > input[type="checkbox"]
        display: none
        &:hover:not(:checked)+label
            .Checkbox__Box
                box-shadow: inset 0 0 0 2px $text-primary-color
                .Icon
                    color: $text-secondary-color
        &:checked+label
            .Checkbox__Box
                box-shadow: inset 0 0 0 2px $primary-color
                background-color: $primary-color

    > label
        display: flex
        align-items: center
        gap: 0.5rem
        cursor: pointer
    &__Box
        width: 1.25rem
        height: 1.25rem
        border-radius: 0.25rem
        display: flex
        justify-content: center
        align-content: center
        padding-top: 2px
        box-shadow: inset 0 0 0 2px $text-secondary-color
        position: relative
        .Icon
            color: $color-white
    &__Label
        color: $text-primary-color