@use '../../variables.sass' as *
    
.SearchBar
    width: 100%
    background-color: $color-white
    // box-shadow: 2px 2px 10px rgba(0,0,0,0.1), 0 -20px 40px 40px rgba(255, 255, 255, 1)
    box-shadow: 2px 2px 10px rgba(0,0,0,0.1)
    border-radius: 0.5rem
    position: sticky
    top: 1rem
    left: 1rem
    margin-bottom: 2rem
    display: flex
    flex-direction: column
    overflow: hidden
    z-index: 2
    &__Top
        display: flex
        align-items: center
        justify-content: flex-end
        gap: 0.5rem
        padding: 0 0.5rem 0 1rem
        &__Field
            width: 100%
            display: flex
            justify-content: flex-start
            align-items: center
            > input
                margin-left: 0.5rem
                width: 100%
                border: 0
                font-family: Roboto
                padding: 1rem 0
                outline: 0
                &::placeholder
                    opacity: 0.5
                    font-style: italic
    &__Bottom
        border-top: 1px solid $color-light-black-opacity
        padding: 1rem
        display: flex
        gap: 1rem
        align-items: center
        > .Button
            position: absolute
            right: 1rem
    &.smart   
        &::after
            content: ''
            width: 200px
            height: 100%
            position: absolute
            right: 0
            top: 0
            background: linear-gradient(45deg, rgba($color-brand-light, 0) 0% , rgba($color-brand-ultra-light, 0.5) 100%)
            pointer-events: none