@use '../../variables.sass' as *
    
.Badge
    text-transform: uppercase
    font-size: 0.5rem
    display: block
    position: absolute
    padding: 0.025rem 0.125rem
    font-weight: bold
    border-radius: 0.15rem
    top: 0
    right: 0
    transform: translateX(25%)
    background-color: $color-gray-opacity
    color: $color-white
    &.new
        background-color: $primary-color
        color: $color-white
    &.beta
        background-color: $accent-color
        color: $color-white
    &.pro
        background-color: $color-status-warning
        color: $color-white
