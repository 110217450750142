$primary-color: #00BE5E
$color-brand-dark: #008C31
$color-brand-light: #AAF0C8
$color-brand-ultra-light: #DAFED7

$text-primary-color: #212121
$text-secondary-color: #727272
$color-text-label: #999999
$color-background-gray: #F2F2F2
$color-white: #FFFFFF

$accent-color: #2196F3
$accent-color-dark: #0067bb
$accent-color-light: #79b9ed
$accent-color-ultra-light: #e4f3ff
$color-status-error: #EE3737
$color-status-error-dark: #7d1d1d
$color-status-error-light: #FFD1D1
$color-status-warning: #FE9900

$color-light-black-opacity: rgba(#000000, 0.12)
$color-black-opacity: rgba(#000000, 0.38)
$color-gray-opacity: rgba(#616161, 0.9)
$color-dark-black-opacity: rgba(#000000, 0.8)
$color-white-opacity: rgba(#FFFFFF, 0.5)
$color-ink: rgba(#000000, 0.06)

$material-grey-icon: #757575