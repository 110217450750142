@use '../../../../variables' as *

.Checklist
    position: absolute
    bottom: 3rem
    right: 3rem
    box-shadow: 2px 2px 20px rgba(0,0,0, .2)
    padding: 0.5rem
    user-select: none
    border-radius: 3rem
    background-color: $color-white
    display: flex
    flex-direction: column
    gap: 1rem
    width: 80px
    max-height: 80px
    overflow: hidden
    cursor: pointer
    > .Header
        display: flex
        justify-content: space-between
        align-items: center
        gap: 1rem
        > .Pct
        > .Labels
            flex: 1
            > .caption
                color: $text-secondary-color 
        > .Button
            align-self: flex-start
    > .List
        opacity: 0
        width: calc(300px - 2rem)
        display: flex
        flex-direction: column
        gap: 0.5rem
        > button
            width: 100%
            height: 2.25rem
            display: flex
            align-items: center
            justify-content: space-between
            background-color: $color-white
            border: 1px solid $color-light-black-opacity
            border-radius: 0.225rem
            padding: 0 0.75rem
            > div
                align-items: center
                display: flex
                gap: 0.5rem
                > .Icon
                    color: $primary-color
            > .caption
                font-weight: bold
                color: $primary-color
            &.done
                background-color: rgba($color-brand-light, .5)
                color: $color-brand-dark
            &:not(.done)
                > button
                cursor: pointer
                &:hover
                    background-color: $color-background-gray
    > .Button
        align-self: flex-end
    > .Action
        color: $color-white
        text-transform: uppercase
        font-size: 0.95rem
        font-weight: bold
        width: 200px
        overflow: hidden
        display: none
        align-items: center
        gap: 0.5rem
    &.opened
        width: 300px
        max-height: 500px
        border-radius: 0.5rem
        cursor: unset
        padding: 1rem
        > .Header
            > .Pct
                height: 40px
        > .List
            opacity: 1
    &.ready
        background-color: $primary-color
        border-radius: 4rem
        max-height: 60px
        cursor: unset
        padding: 1rem
        width: 220px
        cursor: pointer
        > .Header
            display: none
        > .List
            display: none
        > .Button
            display: none
        > .Action
            display: flex
            > .Icon
                color: $color-white
    &.reject
        background-color: $color-status-error-light
        border-radius: 4rem
        max-height: 60px
        cursor: unset
        padding: 1rem
        width: 220px
        > .Header
            display: none
        > .List
            display: none
        > .Button
            display: none
        > .Action
            display: flex
            color: $color-status-error-dark
            > .Icon
                color: $color-status-error-dark
    &:not(.opened)        
        &:hover                    
            box-shadow: 2px 2px 20px rgba(0,0,0, .2), inset 0 0 0 18px rgba($primary-color, 0.1)
            > .Header
                > .Pct
                    transform: scale(1.1)