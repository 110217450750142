@use '../../variables.sass' as *
    
.Accordion
    display: flex
    flex-direction: column
    width: 100%
    overflow: hidden
    > .Header
        width: 100%
        position: relative
        border-bottom: 1px solid $color-light-black-opacity
        min-height: 50px
        > .Handler
            position: absolute
            right: 1rem
            top: 0.75rem
            cursor: pointer
    > .Content
        width: 100%
        height: fit-content
        position: relative
    &.full
        > .Header
            > .Handler
                width: 100%
                height: 100%
                top: 0
                left: 0
                > .Icon
                    position: absolute
                    right: 1rem
                    top: 0.75rem
    