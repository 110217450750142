@use '../../../../variables.sass' as *
    
.OFXCard
    display: flex
    flex-direction: column
    transform: translateX(0rem)
    cursor: pointer
    border-radius: 0.25rem
    user-select: none
    &.readOnly
        cursor: default
    &__Top
        display: flex
        align-items: flex-start
        background-color: $color-white
        border: 1px solid $color-light-black-opacity
        border-bottom: 0
        border-radius: 0.25rem 0.25rem 0 0
        min-height: 53px
        > .Label
            color: $color-text-label
            padding: 0.75rem
            padding-bottom: 0
            flex: 1
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            white-space: normal
        > .Action
            padding: 0.75rem
            padding-bottom: 0
    &__Bottom
        display: flex
        justify-content: space-between
        background-color: $color-white
        border: 1px solid $color-light-black-opacity
        border-top: 0
        border-radius: 0 0 0.25rem 0.25rem 
        > .Value
            color: $color-brand-dark
            flex: 1
            padding: 0.75rem
            &.negative
                color: $color-status-error
        > div
            display: flex
            padding: 0.75rem
    &__Children
        margin-top: -2px
        margin-left: 0.5rem
        border-left: 1px solid $color-light-black-opacity
        border-right: 1px solid $color-light-black-opacity
        background-color: $color-white
        .OFXCard
            &__Top
                border: 0
                border-radius: 0
                background-color: transparent
            &__Bottom
                border: 0
                border-radius: 0
                background-color: transparent
        &:last-child
            border-radius: 0 0 0.25rem 0.25rem         
            border-bottom: 1px solid $color-light-black-opacity              
    &.active:not(.remove)        
        transform: translateX(1rem)
        transition-property: transform
        border-right: 4px solid $color-gray-opacity
        .OFXCard
            &__Top
                background-color: $color-background-gray
                border: 1px solid $color-background-gray
                border-bottom: 0
                > .Label
                    color: $text-primary-color
            &__Bottom
                background-color: $color-background-gray
                border: 1px solid $color-background-gray
                border-top: 0
            &__Children
                background-color: $color-background-gray
                border: 1px solid $color-background-gray
        // position: sticky
        // top: 51px
        // z-index: 2
    
        &.valid      
            border-right: 4px solid $color-brand-dark
            .OFXCard
                &__Top
                    background-color: $color-brand-ultra-light
                    border: 1px solid $color-brand-ultra-light
                    border-bottom: 0
                    > .Label
                        color: $color-brand-dark
                &__Bottom
                    background-color: $color-brand-ultra-light
                    border: 1px solid $color-brand-ultra-light
                    border-top: 0
                &__Children
                    background-color: $color-brand-ultra-light
                    border: 1px solid $color-brand-ultra-light  
    &.reconcile
        // position: sticky
        // top: 51px
        // z-index: 2
        animation: reconcile 300ms 
    &.remove
        // position: sticky
        // top: 51px
        // z-index: 2
        animation: ignore 300ms 
    @keyframes reconcile
        0%
            transform: translateX(0%)
        100%
            transform: translateX(110%)
    @keyframes ignore
        0%
            transform: translateX(0%)
        100%
            transform: translateX(-110%)
            