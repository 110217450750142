@use '../../variables' as *

.OFXArea
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

.OFXTitle
    padding: 1rem
    display: flex
    justify-content: space-between
    align-items: center

.OFXImport
    width: 100%
    height: 100%
    &__Loading
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
    &__Message
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        


.OFXList
    display: flex
    flex-direction: column
    align-items: center
    &__Group
        display: flex
        flex-direction: column
        gap: 1rem
        position: relative
        width: 100%
        &__date
            top: 0
            position: sticky
            background-color: $color-white
            z-index: 1
            padding: 1rem
        > .OFXCard
            margin: 0 1rem
            &:last-child
                margin-bottom: 2rem
    &__Floater
        position: absolute 
        bottom: 3rem
        z-index: 4
        background-color: $color-background-gray
        font-size: 0.825rem
        padding: 0.5rem
        border-radius: 2rem
        display: flex
        align-items: center
        cursor: pointer
        box-shadow: 2px 2px 10px rgba(0,0,0,.1), inset 0 0 0 1px $color-white
        > .Icon
            margin-left: 0.5rem
        > .Counter
            margin-right: 0.5rem
        &.top
            bottom: auto
            top: 7rem
        &.valid
            color: $color-brand-dark
            background-color: $color-brand-ultra-light
            > .Icon
                color: $color-brand-dark
.BILLlist
    width: 100%
    position: relative
    overflow: hidden
    display: flex
    flex-direction: column
    height: 100%
    > .content
        padding: 0 1rem
        padding-bottom: 4rem
        flex: 1
        min-height: 0
    &__Message
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        &__Box
            margin-top: -4rem
            max-width: 400px
            display: flex
            flex-direction: column
            align-items: center
            > div
                color: $text-primary-color
                text-align: center
            > .buttons
                margin-top: 1rem
                display: flex
                gap: 1rem