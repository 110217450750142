@use '../../variables.sass' as *
    
.DropButton
    border: 2px solid $color-light-black-opacity
    border-radius: 4px
    cursor: pointer
    > .Icon
        color: $color-white
    &:hover
        background-color: $color-white
        > .Icon
            color: $primary-color