@use '../../../../variables' as *

.NewNFe
    width: 100vw
    height: 100vh
    background-color: $color-white
    display: flex
    flex-direction: column
    > .Header
        height: 75px
        padding: 0 2rem
        display: flex
        align-items: center
        justify-content: space-between
    &__Content
        background-color: $color-background-gray
        padding: 2rem

.NFeProductRow
    width: 100%
    &__Fields
        display: flex
        gap: 1rem
        > .Icon
            color: $primary-color
            margin-top: 0.85rem
        > .ClientModule
            flex: 2
        > .InputText
            flex: 1

.OverView
    width: 90vw
    height: 90vh
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.1)
    border-radius: 0.5rem
    overflow: hidden