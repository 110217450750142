@use '../../variables.sass' as *
    
.DataGrid
    > .TableHeader
        display: flex
        align-items: center
        border-bottom: 1px solid $color-light-black-opacity
        height: 2.5rem
        > *
            padding: 0.25rem
        > .caption
            flex: 1
            color: $color-text-label
    > .TableRows
        > .TableRow
            display: flex
            align-items: center
            border-bottom: 1px solid $color-light-black-opacity
            cursor: pointer
            > *
                height: 2.5rem
                padding: 0.25rem
            > .caption
                flex: 1
                color: $text-primary-color
                display: flex
                align-items: center
                gap: 0.25rem
            &:hover
                background-color: $color-background-gray