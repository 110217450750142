@use '../../variables.sass' as *
    
.Percent
    width: 100%
    height: 100%
    max-width: 4rem
    max-height: 4rem
    position: relative
    > svg
        width: 100%
        height: 100%    
        > .track, >.fill
            fill: none
            stroke-width: 4px
        > .track
            stroke: rgba($color-gray-opacity, .1)
        > .fill    
            stroke: $primary-color
            stroke-dasharray: 76
            stroke-dashoffset: 0
            transform-origin: center center
            transform: rotate(-90deg)
    &__Labels
        position: absolute
        transform: translate(-50%, -50%)
        top: 50%
        left: 50%
        font-size: 0.825rem
